export default {
  inputHeight: '42px',
  borderRadius: '4px',
  sidebarWidth: '28%',
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '360px',
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  bottomBarHeight: 56,
  columnWidthDate: 160,
  columnWidthNumber: 130,
  columnWidthString: 160,
  columnWidthBoolean: 130,
  popupMapOffset: 300,
  popupMaxWidth: 272,
};
