import React from 'react';
import {
  useMediaQuery, makeStyles,
  useTheme, Paper, Container, Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    background: theme.palette.common.purple,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('xs')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      // padding: theme.spacing(0, 25, 0, 0),
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(4),
    width: '100%',
  },
  paperCustom: {
    padding: '0',
    borderStyle: 'none',
    backgroundColor: 'gray',
    borderRadius: '0',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#fff',
    },
  },
  formContainer1: {
    width: '100%',
    maxWidth: '850px',
    height: '600px ! important',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    paddingRight: '0',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      borderRadius: '0px',
      height: '100%',
    },
  },
  formContainer2: {
    width: '100%',
    maxWidth: '1050px',
    height: '600px ! important',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: '#fff',
    // boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.2)",
    display: 'flex',
    overflow: 'hidden',
    paddingRight: '50px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      borderRadius: '0px',
      height: '100%',
    },
  },
  formContainerTop1: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    background: 'url(/images/reset-bg.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
  },
  formContainerTop2: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    background: 'url(/images/login-bg.svg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  centeredInParent: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const PageLayout = ({ children, resetpage }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      {/* <div className={classes.sidebar} style={{ width: "0px" }}> */}
      {/* {!useMediaQuery(theme.breakpoints.down('md'))
          && (
          <svg height="64" width="240">
            <use xlinkHref="/logo.svg#img" />
          </svg>
          )} */}
      {/* </div> */}
      <Paper className={[classes.paper, classes.paperCustom].join(' ')}>
        {/* <form className={classes.form}>
          { children }
        </form> */}

        <Container
          className={
            resetpage ? classes.formContainer1 : classes.formContainer2
          }
        >
          <Grid
            container
            spacing={2}
            style={{
              height: '100%',
            }}
          >
            {!useMediaQuery(theme.breakpoints.down('xs')) && (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                className={classes.centeredInParent}
              >
                <form className={classes.form}>{children}</form>
              </Grid>
            )}
            {!useMediaQuery(theme.breakpoints.down('xs')) && (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                className={
                  resetpage
                    ? classes.formContainerTop1
                    : classes.formContainerTop2
                }
              >
                {/* <img
                  src={resetbg}
                  // width="300"
                  // height="100%"
                  className={classes.resetbg}
                /> */}
              </Grid>
            )}
            {useMediaQuery(theme.breakpoints.down('xs')) && (
              <Grid item xs={12} className={classes.centeredInParent}>
                <form className={classes.form}>{children}</form>
              </Grid>
            )}
          </Grid>
        </Container>
      </Paper>
    </main>
  );
};

export default PageLayout;
