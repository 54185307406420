/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'fr',
  resources: {
    en: {
      translations: require('./locales/en/en.json'),
    },
    fr: {
      translations: require('./locales/fr/fr.json'),
    },
    de: {
      translations: require('./locales/de/de.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'fr', 'de'];

export default i18n;
