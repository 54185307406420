/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import ReplayIcon from '@material-ui/icons/Replay';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  Container,
  Tooltip,
  Box, Grid,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChartAlarm from '../common/components/dashboard/ChartAlarm';
import ChartOverspeed from '../common/components/dashboard/ChartOverspeed';
import ChartGeofenceViolation from '../common/components/dashboard/ChartGeofenceViolation';
import ChartAssetsInService from '../common/components/dashboard/ChartAssetsInService';

import fleemooIconB from '../../public/images/fleemoo2.png';
import * as selectors from '../common/selectors';
import { useTranslation } from '../common/components/LocalizationProvider';
import { sessionActions } from '../store';
import {
  ShowForPermission,
  GetFeatureNames,
  CheckUserPermission,
  Permissions_Instance,
} from '../settings/UserPermissions';

const useStyles = makeStyles((theme) => ({
  flex: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  appBarContainer: {
    maxHeight: 40,
    backgroundColor: 'transparent',
    marginRight: 'auto',
    maxWidth: '100%',
    borderRadius: 5,
    boxShadow: '0px 10px 40px rgba(82, 85, 114, 0.3)',
  },
  appBarCollapsed: {
    maxHeight: 50,
    backgroundColor: 'transparent',
    borderRadius: 5,
    boxShadow: '0px 10px 40px rgba(82, 85, 114, 0.3)',
    maxWidth: '155px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'transparent',
    paddingTop: '2px',
    paddingRight: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '14px',
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logoContainer: {
    maxWidth: '60%',
    marginLeft: 'auto',
    paddingRight: 10,
  },
  logoContainer2: {
    maxWidth: '100%',
    paddingRight: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '80%',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  reportCard: {
    background: 'transparent',
    boxShadow: '0 0 rgba(0,0,0,0)',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  notActiveName: {
    display: 'none',
    color: '#A9B1C7',
    listStyle: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginRight: 15,
    },
  },
  activeName: {
    display: 'none',
    color: '#ffffff',
    listStyle: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginRight: 15,
    },
  },
  margin2: {
    marginRight: -15,
    marginLeft: -5,
    color: '#0352DA',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  activeLink: {
    marginRight: '-35px',
    marginLeft: '-5px',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      marginRight: '-20px',
      marginLeft: '10px',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '-15px',
      marginLeft: '10px',
    },
  },
  notActiveLink: {
    marginRight: '-35px',
    marginLeft: '-5px',
    color: '#A9B1C7',
    [theme.breakpoints.up('sm')]: {
      marginRight: '-15px',
      marginLeft: '10px',
    },
  },
  logo: {
    width: 95,
    height: 22,
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      width: 125,
      height: 30,
      marginRight: 10,
    },
  },
  logo2: {
    width: 125,
    height: 30,
    marginLeft: 30,
    marginTop: 5,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#A9B1C7 !important',
  },
  active: {
    paddingLeft: 8,
    paddingRight: 7,
    background: '#0352DA',
    borderRadius: 5,
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 13,
      paddingBottom: 13,
    },
  },
  menu: {
    marginTop: '-35px',
    marginLeft: -10,
    [theme.breakpoints.up('sm')]: {
      marginTop: '-60px',
      marginLeft: 8,
    },
  },
  navCollapsed: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#A9B1C7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    left: '-11px',
    top: '14px',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navLinksCollapsed: {
    display: 'none',
  },
  appBar2: {
    maxWidth: '200px',
    marginLeft: 'auto',
    zIndex: theme.zIndex.drawer + 1,
    background: '#ffff55',
    padding: 0,
    paddingBottom: '7px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '14px',
    },
  },
}));

const InfoToolbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const url = useParams();
  const dispatch = useDispatch();
  const t = useTranslation();
  const userId = useSelector(selectors.getUserId);

  const IsLicenseValid = Permissions_Instance.IsLicenseValid();
  const isAdmin = useSelector(selectors.getIsAdmin);
  const IsWebUIUser = isAdmin || IsLicenseValid;

  const isActive_base_localization =
    isAdmin || CheckUserPermission(userId, GetFeatureNames().base_localization);
  const isActive_base_dashboard =
    isAdmin || CheckUserPermission(userId, GetFeatureNames().base_dashboard);
  const isActive_base_tracking =
    isAdmin ||
    CheckUserPermission(userId, GetFeatureNames().base_base_tracking);
  const isActive_fleet_geofencing =
    isAdmin || CheckUserPermission(userId, GetFeatureNames().fleet_geofencing);
  const isActive_notif_notification =
    isAdmin ||
    CheckUserPermission(userId, GetFeatureNames().notif_notification);
  const isActive_driver_management =
    isAdmin || CheckUserPermission(userId, GetFeatureNames().driver_management);
  const isActive_maint_maintancePlan =
    isAdmin ||
    CheckUserPermission(userId, GetFeatureNames().maint_maintancePlan);
  const isActive_fleet_report =
    isAdmin || CheckUserPermission(userId, GetFeatureNames().fleet_Report);

  const [drawer, setDrawer] = useState(false);
  const openDrawer = () => {
    setDrawer(true);
  };
  const closeDrawer = () => {
    setDrawer(false);
  };

  const handleLogout = async () => {
    const response = await fetch('/api/session', { method: 'DELETE' });
    if (response.ok) {
      dispatch(sessionActions.updateUser(null));
      history.push('/login');
    }
  };

  const [navCollapsed, setNavCollapsed] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';

  return (
    <>
      <div
        className={
          `${navCollapsed ? classes.navLinksCollapsed : classes.appBarContainer}`
        }
      >
        <Toolbar className={classes.appBar}>
          <div
            tabIndex={0}
            className={classes.list}
            role="button"
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                className={classes.navCollapsed}
                onClick={() => setNavCollapsed(!navCollapsed)}
              >
                {!navCollapsed ? (
                  <ChevronRightIcon
                    style={{ color: '#fff', fontSize: '24px' }}
                  />
                ) : (
                  <ChevronLeftIcon
                    style={{ color: '#fff', fontSize: '24px' }}
                  />
                )}
              </div>

              {isActive_base_dashboard ? (
                <>
                  <Container maxWidth={false}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <ChartAssetsInService />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <ChartOverspeed />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <ChartGeofenceViolation />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <ChartAlarm />
                      </Grid>
                    </Grid>
                  </Container>
                </>
              ) : null}
            </div>
          </div>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            &nbsp;
          </Typography>
          <div className={classes.grow} />
        </Toolbar>
      </div>
      <div
        className={
          !navCollapsed ? classes.navLinksCollapsed : classes.appBarCollapsed
        }
      >
        <Toolbar className={classes.appBar}>
          <div
            tabIndex={0}
            className={classes.list}
            role="button"
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                className={classes.navCollapsed}
                onClick={() => setNavCollapsed(!navCollapsed)}
              >
                {!navCollapsed ? (
                  <ChevronRightIcon
                    style={{ color: '#fff', fontSize: '28px' }}
                  />
                ) : (
                  <ChevronLeftIcon
                    style={{ color: '#fff', fontSize: '28px' }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classes.logoContainer2}>
            <a href="www.fleemoo.com">
              <img src={fleemooIconB} className={classes.logo2} />
            </a>
          </div>
        </Toolbar>
      </div>
    </>
  );
};

export default InfoToolbar;
