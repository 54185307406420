/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { useTranslation } from '../LocalizationProvider';
import {
  formatDate,
} from '../../util/formatter';

let _dashboardDevicesEvents = null;
const _ContainerID_devicesEvents = 'cont-deviceEvents';

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// data structure for backend data to be rendered
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
class DashboardDeviceEvents {
  constructor() {
    this.resetData();
  }

  resetData() {
    this.data = {};
    this.data.rows = [];
    this.data.columns = [
      { id: 'device', label: i18next.t('sharedName'), minWidth: 70 },
      // { id: 'Driver', label: t('shareD'), minWidth: 50 },
      {
        id: 'alarm',
        label: i18next.t('sharedAlarms'),
        minWidth: 70,
        align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
      },
      // {
      //   id: 'status',
      //   label: t('deviceStatus'),
      //   minWidth: 70,
      //   align: 'right',
      // },
      {
        id: 'alarmTime',
        label: i18next.t('sharedAlarms'),
        minWidth: 170,
        align: 'right',
        format: (value) => formatDate(value),
      },
      {
        id: 'address',
        label: i18next.t('positionAddress'),
        minWidth: 170,
        align: 'right',
      },
    ];
  }

  loadFromJson(rawTrafficDataJson) {
    // this.data.rows = [
    //   this.createTableData('1', new devices 1', 'John Do', 'alarm X', 'online', '2021-07-31T00:00:00.000Z', '12345566', '89899898', 'Fancy Str 1, 12354 CA CM'),
    //   this.createTableData('2', new devices 2', 'Marx B', 'alarm ABC', 'online', '2021-07-31T00:00:00.000Z', '12345566', '89899898', 'Otto Str 9, 8855 CA CM'),
    //   this.createTableData('3', new devices 3', 'Martin Xa', 'SSdfd ', 'online', '2021-07-31T00:00:00.000Z', '12345566', '89899898', 'Fancy Str 3, 9999 CA CM'),
    //   this.createTableData('4', new devices 4', 'General Abdou', 'logg', 'online', '2021-07-31T00:00:00.000Z', '12345566', '89899898', 'Otto Str 34, 12354 CA CM'),
    // ];

    try {
      for (let i = rawTrafficDataJson.length - 1; i >= 0; i--) {
        let item = rawTrafficDataJson[i];
        // console.log('current event: '+JSON.stringify(item));
        let deviceId = item.deviceId == null ? '' : item.deviceId;
        let deviceName = item.deviceName == null ? '' : item.deviceName;
        // let driverName  = (item.driverName == null) ? '' : item.driverName;
        let alarm = item.alarm == null ? '' : item.alarm;
        let status = item.status == null ? '' : item.status;
        let lastUpdate = item.lastUpdate == null ? '' : item.lastUpdate;
        let address = item.address == null ? '' : item.address;
        let latitude = item.latitude == null ? '' : item.latitude;
        let longitude = item.longitude == null ? '' : item.longitude;
        // let dataLine = this.createTableData(deviceId, deviceName, driverName, alarm, status, lastUpdate, latitude, longitude, address);
        let dataLine = this.createTableData(
          deviceId,
          deviceName,
          alarm,
          status,
          lastUpdate,
          latitude,
          longitude,
          address,
        );
        // console.log('event data line = '+dataLine);
        this.data.rows.push(dataLine);
      }
    } catch (ex) {
      console.error('reading event data failed ', ex.message);
    }

    ReactDOM.render(
      <CreateEventTable />,
      document.getElementById(_ContainerID_devicesEvents),
    );
  }

  // createTableData(deviceId, device, driver, alarm, status, alarmTime, latitude, longitude, address) {
  createTableData(
    deviceId,
    device,
    alarm,
    status,
    alarmTime,
    latitude,
    longitude,
    address,
  ) {
    // const density = population / size;
    address = address === '' ? `(${latitude}, ${longitude})` : address;
    alarm = alarm === '' ? status : alarm;
    // return { device, driver, alarm, status, alarmTime, address };
    return {
      device, alarm, alarmTime, address,
    };
  }
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableCellHead: {
    padding: '8px 16px',
    background: '#fff',
    fontFamily: 'SF Pro Display',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#202020',
  },
  tableCellBody: {
    padding: '6px 16px',
    background: '#fff',
    fontFamily: 'SF Pro Display',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#202020',
  },
});

const CreateEventTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="table">
      <TableContainer className="eventListContainer fancyScollbar2">
        <Table stickyHeader aria-label="Event table">
          <TableHead>
            <TableRow>
              {_dashboardDevicesEvents.data.columns.map((column) => (
                <TableCell
                  className={classes.tableCellHead}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_dashboardDevicesEvents.data.rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  style={{ height: '20px' }}
                >
                  {_dashboardDevicesEvents.data.columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={classes.tableCellBody}
                        key={column.id}
                        align={column.align}
                      >
                        <span style={{ fontSize: '12px' }}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ padding: '0px 16px', marginTop: '-8px' }}
        size="small"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={_dashboardDevicesEvents.data.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        className={classes.tableCellBody}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

const ChartEventsTable = (props) => {
  const classes = useStyles();
  const t = useTranslation();

  const [progress, setProgress] = useState(true);

  if (_dashboardDevicesEvents == null) { _dashboardDevicesEvents = new DashboardDeviceEvents(); }

  // load device IDs
  const GetDeviceData = async (from, to, headers) => {
    const query = new URLSearchParams({ from, to });
    const response = await fetch(
      `/api/dashboards/deviceReport?${query.toString()}`,
      { headers },
    );
    setProgress(false);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          let responseJsonObj = await response.json();
          _dashboardDevicesEvents.loadFromJson(responseJsonObj);
        } else {
          console.log(`content type not handled: ${contentType}`);
        }
      }
    } else {
      console.log('GetDeviceData: api response not okay ');
    }
  };

  let dateFrom;
  let dateTo;
  // dateFrom = moment().subtract(1, 'week').startOf('week');
  // dateTo = moment().endOf('week');
  dateFrom = moment().subtract(7, 'days').startOf('day');
  dateTo = moment();
  let valueDescText =
    `${t('dashboardTimeFrameFrom')}: ${
      dateFrom.toISOString()
    } ${t('dashboardTimeFrameTo')}: ${
      dateTo.toISOString()}`;
  console.log(valueDescText);

  const accept = 'application/json';
  GetDeviceData(dateFrom.toISOString(), dateTo.toISOString(), {
    Accept: accept,
  });

  return (
    <Card
      {...props}
      style={{ boxShadow: '0 0 rgba(0,0,0,0)' }}
      className="middleCards"
    >
      {/* <CardHeader title="Events" /> */}
      <div className="card-big-text">
        <p style={{ marginBottom: '-3px' }}>{t('reportEvents')}</p>
        {' '}
      </div>
      {/* <Divider /> */}
      <Paper
        className={classes.root}
        style={{ height: '100%' }}
        id={_ContainerID_devicesEvents}
      >
        {progress ? (
          <div
            style={{
              width: '100%',
              height: '120px',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <CircularProgress style={{ color: '#0352DA' }} />
          </div>
        ) : (
          <CreateEventTable progress={progress} />
        )}
      </Paper>
    </Card>
  );
};

export default ChartEventsTable;
