/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ShowForPermissionComponent = (props) => {
  const couldShow = props.userPermissions.includes(props.permission);
  return couldShow ? props.children : null;
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

let permissionHolder = null;
class Permissions {
  constructor() {
    if (!Permissions.instance) {
      this._data = [];
      Permissions.instance = this;
      this.data = {
        userId: null,
        isAdmin: false,
        subscriptionsJson: null,
        grantedPermissions: null,
      };
    }

    return Permissions.instance;
  }

  Reset() {
    this.data.subscriptionsJson = null;
    this.data.grantedPermissions = null;
    this.data.userId = null;
    this.data.isAdmin = false;
    return true;
  }

  GetPermissions() {
    return this.data.grantedPermissions;
  }

  IsLicenseValid() {
    console.log(`Permissions: IsLicenseValid() = ${this.data.subscriptionsJson}`);
    if (this.data.subscriptionsJson == null) { return true; }

    let data_filtered = this.data.subscriptionsJson.filter((element) => element.isactive === true);
    console.log(`Permissions: unfiltered data count:${this.data.subscriptionsJson.length}`);
    console.log(`Permissions: filtered data count:${data_filtered.length}`);
    let bActiveLicenseFound = data_filtered.length > 0 ? true : false;

    console.log(`Permissions: valid license found = ${bActiveLicenseFound}`);
    return bActiveLicenseFound;
  }

  IsAdmin() {
    console.log(`Permissions: isAdmin() = ${this.data.isAdmin}`);
    return (this.data.isAdmin === undefined) ? false : this.data.isAdmin;
  }

  GetFriendlySubscriptionsList() {
    if (this.data.subscriptionsJson == null) { return false; }

    let reformattedList = this.data.subscriptionsJson.map((item) => {
      item.name = this.GetFriendlySubscriptionName(item.subscriptiontypeuid);
      return item;
    });

    console.log(`Permissions: formatted subscription list${JSON.stringify(reformattedList)}`);
    return reformattedList;
  }

  GetFriendlySubscriptionName(uniqueId) {
    // TODO: tranlate me.!!!!!
    if (uniqueId === 'basi') return 'Fleemoo Basic';

    if (uniqueId === 'driv') return 'Fleemoo Driver';

    if (uniqueId === 'flee') return 'Fleemoo Fleet';
    if (uniqueId === 'main') return 'Fleemoo Maintenance';
    if (uniqueId === 'noti') return 'Fleemoo Notificitaion';
    if (uniqueId === 'othe') return 'Fleemoo Others';
    return uniqueId;
  }
}

const Permissions_Instance = new Permissions();
Object.freeze(Permissions_Instance);

function CheckUserPermission(userId, feature) {
  // if (Permissions_Instance.GetUserID() != userId){
  //     Permissions_Instance.SetUserID(userId);
  //     Permissions_Instance.Load();
  // }
  const p = Permissions_Instance.GetPermissions();
  if (p != null) { return p.includes(feature); }
  return false;
}

const mapStateToProps = (state) => ({
  userPermissions: Permissions_Instance.GetPermissions(),
});

const availableFeatures = Object.freeze({

  base_dashboard: 'dash',
  base_localization: 'loca',
  base_poi: 'poi',
  base_tracking: 'trac',

  fleet_borderManagement: 'boma',
  fleet_carManagement: 'cama',
  fleet_fuelMonitoring: 'fumo',
  fleet_geofencing: 'geof',
  fleet_report: 'flre',

  analytics_carAnalytics: 'car',
  analytics_driverAnalytics: 'driv',
  analytics_fuelAnalytics: 'fuel',
  analytics_roadAnalytics: 'road',
  analytics_speedAnalytics: 'spee',

  driver_coach: 'coac',
  driver_management: 'mana',
  driver_report: 'repo',
  driver_score: 'scor',

  maint_healthCheck: 'hech',
  maint_maintancePlan: 'mapl',
  maint_report: 'mare',

  notif_notification: 'seno',

  others_connectD: 'cond',
  others_generatorManagement: 'gema',
  others_HJProtection: 'hjpr',
  others_liveCarRelease: 'lcre',
  others_tankMonitoring: 'tamo',

});

export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);
export const GetFeatureNames = () => availableFeatures;
export { CheckUserPermission };
export { Permissions_Instance };
