/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  Container,
  makeStyles,
  Tooltip,
  Box, Grid, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18next from 'i18next';
import MainToolbar from './MainToolbar';
import ChartFleetOverview from '../common/components/dashboard/ChartFleetOverview';
import ChartAlarm from '../common/components/dashboard/ChartAlarm';
import ChartOverspeed from '../common/components/dashboard/ChartOverspeed';
import ChartGeofenceViolation from '../common/components/dashboard/ChartGeofenceViolation';
import ChartAssetsInService from '../common/components/dashboard/ChartAssetsInService';
import ChartEventsTable from '../common/components/dashboard/ChartEventsTable';
import ChartMultiTraffic from '../common/components/dashboard/ChartMultiTraffic';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100vh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  controlPanel: {
    position: 'absolute',
    bottom: theme.spacing(5),
    left: '50%',
    transform: 'translateX(-50%)',
  },
  controlContent: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  configForm: {
    display: 'flex',
    flexDirection: 'column',
    // fleemoo
    backgroundColor: '#fff',
    // background: 'rgb(255,159,0)',
    // background: 'linear-gradient(-90deg, rgba(255,159,0,1) 0%, rgba(255,159,0,1) 9%, rgba(250,244,220,1) 100%)',
  },
  graybar: {
    // test
    backgroundColor: theme.palette.common.gray,
    color: '#ffffff',
  },
  contrastColor: {
    color: '#ffffff',
  },
  title: {
    fontFamily: 'SF Pro Display',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '100%',
    letterSpacing: '0.75px',
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
    },
  },
  mainToolbar: {
    position: 'fixed',
    zIndex: 100,
    height: 50,
    left: 0,
    right: 0,
    bottom: 0,
    width: '(100%-0px)',
    borderRadius: 5,
    [theme.breakpoints.up('sm')]: {
      bottom: 25,
      right: '25px',
      left: '25px',
      width: '(100%-50px) !important',
    },
  },
}));

const TimeLabel = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

const DashboardPage = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSubmit = async (deviceId, from, to, _, headers) => {
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      setIndex(0);
      setPositions(await response.json());
      setExpanded(false);
    }
  };

  return (
    <div className={classes.root}>
      {/* <Container
        style={{ backgroundColor: "#F2F7FF", marginBottom: "-25px" }}
        maxWidth={false}
      >
        <h1 className={classes.title}>{t("dashboardTitle")}</h1>
      </Container> */}
      <div className={classes.content}>
        <Box
          sx={{
            // backgroundColor: 'background.default',
            backgroundColor: '#F2F7FF',
            maxHeight: '100%',
            py: 3,
            // overflow: 'scroll'
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <ChartAssetsInService />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ChartOverspeed />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ChartGeofenceViolation />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ChartAlarm />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
              >
                <ChartEventsTable />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
              >
                <ChartFleetOverview />
              </Grid>
            </Grid>
          </Container>
          <Typography variant="button" display="block" gutterBottom style={{ marginTop: '10px', marginLeft: '23px' }}>{i18next.t('overviewDay')}</Typography>
          <ChartMultiTraffic
            style={{ marginTop: '10px' }}
          />
          <Typography variant="button" display="block" gutterBottom style={{ marginTop: '10px', marginLeft: '23px' }}>{i18next.t('overviewWeek')}</Typography>
          <ChartMultiTraffic
            style={{ marginTop: '10px' }}
          />
          <Typography variant="button" display="block" gutterBottom style={{ marginTop: '10px', marginLeft: '23px' }}>{i18next.t('overviewMonth')}</Typography>
          <ChartMultiTraffic
            style={{ marginTop: '10px' }}
          />
        </Box>
      </div>
      <div className={classes.mainToolbar}>
        <MainToolbar />
      </div>
    </div>
  );
};

export default DashboardPage;
