/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import React, { useState } from 'react';
import {
  Grid,
  Container,
  CircularProgress,
  Box,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import '../../../assets/css/fleemoo.css';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import i18next from 'i18next';

let _dashboardDataWeek1 = null; // new DashboardTrafficData();
let _dashboardDataWeek2 = null; // new DashboardTrafficData();
const _week = [
  i18next.t('daySundayShort'),
  i18next.t('dayMondayShort'),
  i18next.t('dayTuesdayShort'),
  i18next.t('dayWednesdayShort'),
  i18next.t('dayThursdayShort'),
  i18next.t('dayFridayShort'),
  i18next.t('daySaturdayShort'),
];
const _chartId_duration = 'apexchart-trafficDuration';
const _chartId_distance = 'apexchart-trafficDistance';
const _chartId_fuel = 'apexchart-trafficFuel';

const useStyles = makeStyles((theme) => ({
  reportFooter: {
    textAlign: 'left',
    marginTop: '4px',
    paddingTop: '4px',
    borderTop: '1px solid #FF9F00',
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#EFEFEF',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#00BFFF',
    },
  },
}));

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Convert data to desired data structure
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
class DashboardTrafficData {
  constructor(startTime, endTime) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.resetData();
  }

  resetData() {
    this.data = {
      days: [
        i18next.t('daySundayShort'),
        i18next.t('dayMondayShort'),
        i18next.t('dayTuesdayShort'),
        i18next.t('dayWednesdayShort'),
        i18next.t('dayThursdayShort'),
        i18next.t('dayFridayShort'),
        i18next.t('daySaturdayShort'),
      ],
      tripDuration: [],
      distance: [],
      spentFuel: [],
      legend: [],
    };
  }

  loadFromJson(rawTrafficDataJson) {
    let start = this.startTime;
    let end = this.endTime;
    // var newend = end.setDate(end.getDate()+1);
    // end = new Date(newend);
    let queryTimeFrom = new Date();
    let queryTimeTo = new Date();

    this.resetData();
    while (start < end) {
      queryTimeFrom = new Date(start);
      queryTimeFrom.setHours(0, 0, 0, 0);
      queryTimeTo = new Date(start);
      queryTimeTo.setHours(23, 59, 59, 999);

      console.log(`filtering fromxxx: ${queryTimeFrom} to:${queryTimeTo}`);
      let filteredData = DataFilterHelper.dateFilterer(
        rawTrafficDataJson,
        queryTimeFrom,
        queryTimeTo,
      );
      DataFilterHelper.preview_json(filteredData.finalValues);
      console.log(`filtered data lengthxxx = ${filteredData.finalValues.length}`);

      let durationSum = filteredData.finalValues.reduce((
        accumulator,
        item,
      ) => accumulator + item.duration,
      0);

      let distanceSum = filteredData.finalValues.reduce(((
        accumulator,
        item,
      ) => accumulator + Math.abs(item.distance)), // todo: fix this implementation. normaly the value should never be negative!!!
      0);

      let spentFuelSum = filteredData.finalValues.reduce(((
        accumulator,
        item,
      ) => accumulator + Math.abs(item.spentFuel)), // todo: fix this implementation. normaly the value should never be negative!!!,
      0);

      console.log(
        `data to store: day=${
          _week[queryTimeFrom.getDay()]
        }, durationSum = ${
          durationSum
        }, legend = ${
          queryTimeFrom.toDateString()}`,
      );
      this.data.days.push(_week[queryTimeFrom.getDay()]);
      this.data.tripDuration.push(durationSum);
      this.data.distance.push(distanceSum);
      this.data.spentFuel.push(spentFuelSum);
      this.data.legend.push(queryTimeFrom.toDateString());

      // incrememnt for new loop
      let newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);
    }

    console.log('--------------------------------------');
    console.log(`days = ${this.data.days.toString()}`);
    console.log(`dispance = ${this.data.distance.toString()}`);
    console.log(`duration = ${this.data.tripDuration.toString()}`);
    console.log(`fuel = ${this.data.spentFuel.toString()}`);
    console.log(`legend = ${this.data.legend.toString()}`);
    console.log('--------------------------------------');

    console.log('updating charts');
    let series = [
      {
        data: _dashboardDataWeek1.data.tripDuration,
      },
      {
        data: _dashboardDataWeek2.data.tripDuration,
      },
    ];

    console.log(` ----------->> Running chart update : ${_chartId_duration}`);
    ApexCharts.exec(_chartId_duration, 'updateSeries', series);

    series = [
      {
        data: _dashboardDataWeek1.data.distance,
      },
      {
        data: _dashboardDataWeek2.data.distance,
      },
    ];

    console.log(` ----------->> Running chart update : ${_chartId_distance}`);
    ApexCharts.exec(_chartId_distance, 'updateSeries', series);

    series = [
      {
        data: _dashboardDataWeek1.data.spentFuel,
      },
      {
        data: _dashboardDataWeek2.data.spentFuel,
      },
    ];

    console.log(` ----------->> Running chart update : ${_chartId_fuel}`);
    ApexCharts.exec(_chartId_fuel, 'updateSeries', series);
  }
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//  Helper classes
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
class DataFilterHelper {
  // Filter function to ensure that the final result set is within a particular range
  static dateFilterer(sourceValues, startDate, endDate) {
    /* Curtosy of the tutorial found here: https://www.w3schools.com/jsref/jsref_filter.asp */
    function filterFunction_dates(sourceValue) {
      // Aim: To test if the tested date is valid within a particular range
      let rangeAcceptance = {
        minValid: new Date(sourceValue.startTime) >= new Date(startDate),
        maxValid: new Date(sourceValue.startTime) <= new Date(endDate),
      };

      let acceptanceResult; // boolean to determine if the relevant range specified is valid

      if (startDate !== '' && endDate !== '') {
        acceptanceResult = rangeAcceptance.minValid && rangeAcceptance.maxValid;
      } else if (startDate !== '') {
        acceptanceResult = rangeAcceptance.minValid;
      } else if (endDate !== '') {
        acceptanceResult = rangeAcceptance.maxValid;
      } else {
        acceptanceResult = true; // show all results if no specific range has been selected
      }
      return acceptanceResult;
    }

    console.log({
      originalValues: sourceValues,
      finalValues: sourceValues.filter(filterFunction_dates),
      'time of filter': new Date(),
    });
    // Return the data for display
    return {
      originalValues: sourceValues,
      finalValues: sourceValues.filter(filterFunction_dates),
    };
  }

  // Aim: To make the dates into something that the Range filter function can work with
  static normalize_date(fieldValue) {
    let monthPossibilities = {
      longStructure: [
        i18next.t('monthJanuary'),
        i18next.t('monthFebruary'),
        i18next.t('monthMarch'),
        i18next.t('monthApril'),
        i18next.t('monthMay'),
        i18next.t('monthJune'),
        i18next.t('monthJuly'),
        i18next.t('monthAugust'),
        i18next.t('monthSeptember'),
        i18next.t('monthOctober'),
        i18next.t('monthNovember'),
        i18next.t('monthDecember'),
      ],
      shortStructure: [
        i18next.t('monthJanuaryShort'),
        i18next.t('monthFebruaryShort'),
        i18next.t('monthMarchShort'),
        i18next.t('monthAprilShort'),
        i18next.t('monthMayShort'),
        i18next.t('monthJuneShort'),
        i18next.t('monthJulyShort'),
        i18next.t('monthAugustShort'),
        i18next.t('monthSeptemberShort'),
        i18next.t('monthOctoberShort'),
        i18next.t('monthNovemberShort'),
        i18next.t('monthDecemberShort'),
      ],
    }; // To store what the month values could possibly be for returning their relevant index number

    let datePartitions = fieldValue.split(', ');
    let year = datePartitions[1];
    let month = datePartitions[0].substring(0, 3); // first three characters of section 1

    if (monthPossibilities.longStructure.indexOf(month) > -1) {
      month = (monthPossibilities.longStructure.indexOf(month) + 1).toString(); // Increments by one to give the actual month number
    } else if (monthPossibilities.shortStructure.indexOf(month) > -1) {
      month = (monthPossibilities.shortStructure.indexOf(month) + 1).toString();
    } else {
      month = '';
    }

    // Aim: Add that customary zero to prepend the date value
    if (month.length < 2 && month.length > 0) {
      month = `0${month}`;
    }

    let day = datePartitions[0].slice(-2); // last two characters of section 1
    let finalResult = `${year}-${month}-${day}`;

    return finalResult;
  }

  // Aim: Display json in a nicely formatted way
  static preview_json(data, target) {
    /* Curtosy of fellow fiddler: http://jsfiddle.net/unLSJ/ */
    let library = {};
    library.json = {
      replacer(match, pIndent, pKey, pVal, pEnd) {
        let key = '<span class=json-key>';
        let val = '<span class=json-value>';
        let str = '<span class=json-string>';
        let r = pIndent || '';
        if (pKey) r = `${r + key + pKey.replace(/[": ]/g, '')}</span>: `;
        if (pVal) r = `${r + (pVal[0] === '"' ? str : val) + pVal}</span>`;
        return r + (pEnd || '');
      },
      prettyPrint(obj) {
        let jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
        return JSON.stringify(obj, null, 3)
          .replace(/&/g, '&amp;')
          .replace(/\\"/g, '&quot;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(jsonLine, library.json.replacer);
      },
    };

    // Show json in desired target
    if (target !== undefined) target.html(library.json.prettyPrint(data));
  }
}

class TrafficChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: i18next.t('dashbordCurrentWeek'),
          data: props.chartDataSeries1,
        },
        {
          name: i18next.t('dashbordPreviousWeek'),
          data: props.chartDataSeries2,
        },
      ],
      options: {
        chart: {
          height: 200,
          type: 'area',
          id: props.chartId,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          labels: {
            formatter: props.chartFormatter,
            style: {
              colors: '#A5A5A5',
            },
          },
        },
        colors: props.chartColors,
        legend: {
          position: 'top',
          offsetX: 70,
          width: 350,
          labels: {
            colors: '#A5A5A5',
          },
          markers: {
            width: 12,
            height: 12,
            radius: 12,
          },
          itemMargin: {
            horizontal: 20,
          },
        },
        xaxis: {
          categories: props.chartDataLegend,
          labels: {
            style: {
              colors: '#A5A5A5',
            },
          },
          axisBorder: {
            show: true,
            height: 0,
          },
        },
        grid: {
          show: true,
          borderColor: '#dddddd',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div id={this.state.options.chart.id}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={180}
        />
      </div>
    );
  }
}

const ChartMultiTraffic = (props) => {
  // const classes = useStyles();
  let deviceIDs = [];

  const [progress, setProgress] = useState(true);

  if (_dashboardDataWeek1 == null) { _dashboardDataWeek1 = new DashboardTrafficData(); }

  if (_dashboardDataWeek2 == null) { _dashboardDataWeek2 = new DashboardTrafficData(); }

  // load device IDs
  const GetDeviceIdsAndLoadTrafficData = async (headers) => {
    const response = await fetch('/api/devices', { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          setProgress(false);
          let responseJsonObj = await response.json();
          deviceIDs = [];
          for (let i = 0; i < responseJsonObj.length; i++) {
            deviceIDs.push(responseJsonObj[i].id);
          }
          console.log(`backend deviceIDs = ${deviceIDs}`);

          GetTrafficData();
        } else {
          console.log(`content type not handled: ${contentType}`);
        }
      }
    }
  };

  function GetTrafficData() {
    const reportData = async (from, to, headers) => {
      let query = new URLSearchParams(deviceIDs.map((s) => ['deviceId', s]));
      query.append('from', from);
      query.append('to', to);
      const response = await fetch(`/api/reports/trips?${query.toString()}`, {
        headers,
      });
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType) {
          if (contentType === 'application/json') {
            let responseJsonObj = await response.json();

            // let startTime = moment().subtract(14, 'days').startOf('day');
            // let startEnd = moment(startTime).add(6, 'days').endOf('day');
            let startTime = moment().subtract(1, 'week').startOf('week');
            let startEnd = moment().subtract(1, 'week').endOf('week');

            _dashboardDataWeek2.startTime = startTime.toDate();
            _dashboardDataWeek2.endTime = startEnd.toDate();
            _dashboardDataWeek2.loadFromJson(responseJsonObj);

            // startTime = moment(startEnd).add(1, 'days').startOf('day');
            // startEnd = moment(startTime).add(6, 'days').endOf('day');
            startTime = moment().startOf('week');
            startEnd = moment().endOf('week');
            _dashboardDataWeek1.startTime = startTime.toDate();
            _dashboardDataWeek1.endTime = startEnd.toDate();
            _dashboardDataWeek1.loadFromJson(responseJsonObj);
          } else {
            console.log(`content type not handled: ${contentType}`);
          }
        } else {
          console.log(' ----------->> no contain type');
        }
      } else {
        console.log(' ----------->> api call response not ok');
      }
    };

    let dateFrom;
    let dateTo;

    dateFrom = moment().subtract(1, 'week').startOf('week'); // new Date("2021-07-31T00:00:00.000Z");
    dateTo = moment().subtract(1, 'days').endOf('day'); // new Date ("2021-08-13T23:59:59.999Z");
    // dateFrom = new Date("2021-07-31T00:00:00.000Z");
    // dateTo = new Date ("2021-08-13T23:59:59.999Z");
    let valueDescText =
      `${i18next.t('dashboardTimeFrameFrom')}: ${dateFrom.toString()} ${i18next.t('dashboardTimeFrameTo')}: ${dateTo.toString()}`;
    console.log(valueDescText);
    const accept = 'application/json';
    reportData(
      dateFrom.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateTo.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      { Accept: accept },
    );
  }

  // GetData();
  GetDeviceIdsAndLoadTrafficData();
  // deviceIDs = [1,2,3,4,5];

  const durationFormatter = function (millisec) {
    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = '';
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : `0${hours}`;
      minutes -= hours * 60;
      minutes = minutes >= 10 ? minutes : `0${minutes}`;
    }

    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : `0${seconds}`;
    if (hours !== '') {
      return `${hours}:${minutes}:${seconds}`;
    }
    return `${minutes}:${seconds}`;
  };

  const numberFormatter = function (value) {
    let val = Math.abs(value);
    if (val >= 1000) {
      val = `${(val / 1000).toFixed(0)} K`;
    }
    return val;
  };
  const zeroFormatter = function (value) {
    return value;
  };

  return (
    <Container
      maxWidth={false}
      style={{ marginTop: '10px', background: '#F2F7FF' }}
    >
      <Grid container spacing={2}>
        {/* Chart hours */}
        <Grid item xs={12} sm={6} md={4}>
          <div>
            {' '}
            {/* <div className={classes.chartFill}> */}
            <Card {...props} style={{ boxShadow: '0 0 rgba(0,0,0,0)' }}>
              <div className="card-big-text">
                <p style={{ marginBottom: '-15px' }}>
                  {i18next.t('sharedHoursPerDay')}
                </p>
                {' '}
              </div>
              {/* <Divider /> */}
              <CardContent>
                <Box
                  style={{
                    height: '30vh',
                    maxHeight: '200px',
                    position: 'relative',
                  }}
                >
                  {progress && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      <CircularProgress style={{ color: '#0352DA' }} />
                    </div>
                  )}
                  <TrafficChart
                    chartId={_chartId_duration}
                    chartDataSeries1={_dashboardDataWeek1.data.tripDuration}
                    chartDataSeries2={_dashboardDataWeek2.data.tripDuration}
                    chartDataLegend={_dashboardDataWeek1.data.days}
                    chartColors={['#5775FC', '#d0d3d4']}
                    chartFormatter={durationFormatter}
                  />
                </Box>
              </CardContent>
            </Card>
          </div>
        </Grid>

        {/* //!Chart hours */}

        {/* // Chart distance */}
        <Grid item xs={12} sm={6} md={4}>
          <div>
            {' '}
            {/* <div className={classes.chartFill}> */}
            <Card {...props} style={{ boxShadow: '0 0 rgba(0,0,0,0)' }}>
              <div className="card-big-text">
                <p style={{ marginBottom: '-15px' }}>{i18next.t('sharedDistance')}</p>
                {' '}
              </div>
              {/* <Divider /> */}
              <CardContent>
                <Box
                  style={{
                    height: '30vh',
                    maxHeight: '200px',
                    position: 'relative',
                  }}
                >
                  {' '}
                  {progress && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      <CircularProgress style={{ color: '#0352DA' }} />
                    </div>
                  )}
                  <TrafficChart
                    chartId={_chartId_distance}
                    chartDataSeries1={_dashboardDataWeek1.data.distance}
                    chartDataSeries2={_dashboardDataWeek2.data.distance}
                    chartDataLegend={_dashboardDataWeek1.data.days}
                    chartColors={['#FFA031', '#d0d3d4']}
                    chartFormatter={numberFormatter}
                  />
                </Box>
              </CardContent>
            </Card>
          </div>
        </Grid>

        {/* //! Chart distance */}

        {/* // Chart fuel */}
        <Grid item xs={12} sm={6} md={4}>
          <div>
            {' '}
            {/* <div className={classes.chartFill}> */}
            <Card {...props} style={{ boxShadow: '0 0 rgba(0,0,0,0)' }}>
              <div className="card-big-text">
                <p style={{ marginBottom: '-15px' }}>{i18next.t('positionFuel')}</p>
                {' '}
              </div>
              {/* <Divider /> */}
              <CardContent>
                <Box
                  style={{
                    height: '30vh',
                    maxHeight: '200px',
                    position: 'relative',
                  }}
                >
                  {' '}
                  {progress && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      <CircularProgress style={{ color: '#0352DA' }} />
                    </div>
                  )}
                  <TrafficChart
                    chartId={_chartId_fuel}
                    chartDataSeries1={_dashboardDataWeek1.data.spentFuel}
                    chartDataSeries2={_dashboardDataWeek2.data.spentFuel}
                    chartDataLegend={_dashboardDataWeek1.data.days}
                    chartColors={['#15E474', '#d0d3d4']}
                    chartFormatter={zeroFormatter}
                  />
                </Box>
              </CardContent>
            </Card>
          </div>
        </Grid>
        {/* //! Chart fuel */}
      </Grid>
    </Container>
  );
};

export default ChartMultiTraffic;
