/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  Card,
  CardContent,
  Grid,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from '../LocalizationProvider';
import t from '../../util/localization';

let _dashboardFleetDevices = null; // new DashboardFleetDevices();

let _chartID_fleetOverview = 'apexchart-fleetoverview';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '50px',
  },
}));

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// data structure for backend data to be rendered
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
class DashboardFleetDevices {
  constructor() {
    this.resetData();
  }

  resetData() {
    this.data = {
      quantities: [],
      totalCount: 0,
      colors: [],
      legend: [],
    };
    // const dataSeries = [50, 10, 4, 10, 7];
    // const dataSeriesSum = 155;
    // const dataSeriesLabel = [
    //   "#1ab7ea",
    //   "#0084ff",
    //   "#39539E",
    //   "#0077B5",
    //   "#1ab7ea",
    // ];
    // const dataSeriesLegend = [
    //   "Device 1",
    //   "Device 2",
    //   "Device 3",
    //   "Device 4",
    //   "Others",
    // ];
  }

  loadFromJson(rawTrafficDataJson) {
    // while(start < end){
    console.log(`devices list length = ${rawTrafficDataJson.length}`);
    this.resetData();
    this.data.totalCount = rawTrafficDataJson.length;
    let catCounter = 0;

    let data_filtered_car = rawTrafficDataJson.filter(
      (element) => element.category === 'car',
    );
    this.data.quantities.push(data_filtered_car.length);
    this.data.legend.push(t('deviceCategoryCar'));
    this.data.colors.push('#1ab7ea');
    catCounter += data_filtered_car.length;

    let data_filtered_truck = rawTrafficDataJson.filter(
      (element) => element.category === 'truck',
    );
    this.data.quantities.push(data_filtered_truck.length);
    this.data.legend.push(t('deviceCategoryTruck'));
    this.data.colors.push('#1ab7ea');
    catCounter += data_filtered_truck.length;

    let data_filtered_bus = rawTrafficDataJson.filter(
      (element) => element.category === 'bus',
    );
    this.data.quantities.push(data_filtered_bus.length);
    this.data.legend.push(t('deviceCategoryBus'));
    this.data.colors.push('#0084ff');
    catCounter += data_filtered_bus.length;

    let data_filtered_pickup = rawTrafficDataJson.filter(
      (element) => element.category === 'pickup',
    );
    this.data.quantities.push(data_filtered_pickup.length);
    this.data.legend.push(t('deviceCategoryPickup'));
    this.data.colors.push('#0077B5');
    catCounter += data_filtered_pickup.length;

    this.data.quantities.push(this.data.totalCount - catCounter);
    this.data.legend.push(t('deviceCategoryOther'));
    this.data.colors.push("'#1ab7ea");

    console.log('--------------------------------------');
    console.log(`devices = ${this.data.quantities.toString()}`);
    console.log(`colors = ${this.data.colors.toString()}`);
    console.log(`totalcount = ${this.data.totalCount}`);
    console.log('--------------------------------------');

    console.log(` --> Running chart update : ${_chartID_fleetOverview}`);
    let series = [
      {
        data: this.data.quantities,
      },
    ];
    ApexCharts.exec(_chartID_fleetOverview, 'updateSeries', series);
  }
}

class DevicesByType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: props.chartData,
      options: {
        chart: {
          id: props.chartId,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          donut: {
            // offsetY: -125,
            // startAngle: 0,
            // endAngle: 270,
            // hollow: {
            //   margin: 5,
            //   size: '30%',
            //   background: 'transparent',
            //   image: undefined,
            // },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#f2f2f2',
              strokeWidth: '140%',
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: 12,
              },
              value: {
                show: true,
                fontSize: 4,
                // fontSize: '14px',
                //  formatter: function (val) {
                //    return val + '%'
                //  }
              },
              total: {
                show: true,
                label: 'Total',
                color: '#373d3f',
                // fontSize: '16px',
                // fontFamily: undefined,
                // fontWeight: 600,
                formatter() {
                  return _dashboardFleetDevices.data.totalCount; // props.chartDataSum;
                },
              },
            },
          },
        },
        color: props.chartDataLabel,
        labels: props.chartDataLegend,
        legend: {
          show: true,
          floating: true,
          fontSize: '14px',
          position: 'left',
          offsetX: -35,
          offsetY: -15,
          labels: {
            colors: '#A5A5A5',
          },
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
          formatter(seriesName, opts) {
            return (
              `  ${
                seriesName
              }:  ${
                opts.w.globals.series[opts.seriesIndex]}`
            );
          },
          itemMargin: {
            vertical: 10,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  }

  //   componentWillMount() {
  //     const scriptTag = document.createElement("script");
  //     scriptTag.src = "./public/js/pagereload.js";
  //     scriptTag.async = true;
  //     document.body.appendChild(scriptTag);
  // }

  render() {
    return (
      // <div id="chart" ref={el => (this.div = el)}>
      <div id="chart" style={{ marginTop: '-30px' }} className="donutChart">
        <input type="hidden" id="refreshed" value="no" />
        <Helmet>
          <script src="./public/js/pagereload.js" type="text/babel" />
        </Helmet>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="255px"
        />
      </div>
    );
  }
}

// function debounce(fn, ms) {
//   let timer;
//   return (_) => {
//     clearTimeout(timer);
//     timer = setTimeout((_) => {
//       timer = null;
//       fn.apply(this, arguments);
//     }, ms);
//   };
// }

const ChartFleeOverview = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const t = useTranslation();

  const [progress, setProgress] = useState(true);
  const [memo, setMemo] = useState();

  if (_dashboardFleetDevices == null) { _dashboardFleetDevices = new DashboardFleetDevices(); }

  const GetDeviceData = async (headers) => {
    const response = await fetch('/api/devices', { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          let responseJsonObj = await response.json();
          _dashboardFleetDevices.loadFromJson(responseJsonObj);
          setProgress(false);
        } else {
          console.log(`content type not handled: ${contentType}`);
        }
      }
    }
  };

  GetDeviceData();

  // useEffect(() => {
  //   const debouncedHandleResize = debounce(function handleResize() {
  //     setMemo(() => (
  //       <DevicesByType
  //         chartId={_chartID_fleetOverview}
  //         chartData={_dashboardFleetDevices.data.quantities}
  //         chartDataLabel={_dashboardFleetDevices.data.colors}
  //         chartDataLegend={_dashboardFleetDevices.data.legend}
  //         chartDataSum={_dashboardFleetDevices.data.totalCount}
  //       />
  //     ));
  //   }, 100);

  //   window.addEventListener("resize", debouncedHandleResize);

  //   return (_) => {
  //     window.removeEventListener("resize", debouncedHandleResize);
  //   };
  // }, [debounce]);

  useEffect(() => {
    setTimeout(() => {
      setMemo(() => (
        <DevicesByType
          chartId={_chartID_fleetOverview}
          chartData={_dashboardFleetDevices.data.quantities}
          chartDataLabel={_dashboardFleetDevices.data.colors}
          chartDataLegend={_dashboardFleetDevices.data.legend}
          chartDataSum={_dashboardFleetDevices.data.totalCount}
        />
      ));
    }, 2500);
  }, []);

  // const ChartMemo = useMemo(
  //   () => (
  //     <DevicesByType
  //       chartId={_chartID_fleetOverview}
  //       chartData={_dashboardFleetDevices.data.quantities}
  //       chartDataLabel={_dashboardFleetDevices.data.colors}
  //       chartDataLegend={_dashboardFleetDevices.data.legend}
  //       chartDataSum={_dashboardFleetDevices.data.totalCount}
  //     />
  //   ),
  //   [_chartID_fleetOverview]
  // );

  return (
    <Card
      {...props}
      style={{ boxShadow: '0 0 rgba(0,0,0,0)' }}
      className={(classes.card, 'middleCards')}
    >
      {/* <CardHeader title="Fleet overview" /> */}
      <div className="card-big-text">
        <p id="fleettitel">
          {t('sharedFleetOverview')}
          {' '}
        </p>
        {' '}
      </div>
      {/* <Divider /> */}
      <CardContent>
        <div className={classes.root}>
          <Grid container spacing={1} style={{ height: '100%' }}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                {progress ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    <CircularProgress style={{ color: '#0352DA' }} />
                  </div>
                ) : (
                  <>{memo}</>
                )}
                <DevicesByType
                  chartId={_chartID_fleetOverview}
                  chartData={_dashboardFleetDevices.data.quantities}
                  chartDataLabel={_dashboardFleetDevices.data.colors}
                  chartDataLegend={_dashboardFleetDevices.data.legend}
                  chartDataSum={_dashboardFleetDevices.data.totalCount}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChartFleeOverview;
