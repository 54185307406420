import React, { useState } from 'react';
import {
  Grid, useMediaQuery, makeStyles, Typography, Select, MenuItem, FormControl, Button, TextField, Snackbar, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import CachedIcon from '@material-ui/icons/Cached';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import logoSvg from '../assets/images/logo.svg';
import fleemooIcon from '../assets/images/fleemoo2.png';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  titleContainer: {
    marginTop: '0px',
    marginBottom: '30px',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '40px',
    },
  },
  title: {
    fontSize: theme.spacing(4),
    fontWeight: 600,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(3.3),
    },
  },
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
    },
  },
  extraContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  registerButton: {
    minWidth: 'unset',
  },
  resetPassword: {
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  input: {
    fontSize: '15px',
    letterSpacing: '0.625px',
    color: '#A9B1C7',
    background: '#F2F2F2',
    boxShadow: '0px 0px 10px -5px rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
  },
  submit: {
    fontSize: '18px',
    letterSpacing: '0.625px',
    color: '#FFFFFF',
    background: '#033CD3',
    borderRadius: '10px',
    '&:hover': {
      background: '#033CD3',
      opacity: 0.9,
    },
  },
  language: {
    fontSize: '16px',
    letterSpacing: '0.625px',
    color: '#A9B1C7',
    background: '#fff',
    textAlign: 'right',
    width: 'max-content',
    marginLeft: 'auto',
  },
  resetLink: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.625px',
    color: '#A9B1C7',
    paddingTop: '20px',
    paddingRight: '5px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');

  // const registrationEnabled = useSelector((state) => state.session.server?.registration);
  // const emailEnabled = useSelector((state) => state.session.server?.emailEnabled);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server?.announcement);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
      });
      if (response.ok) {
        const user = await response.json();
        dispatch(sessionActions.updateUser(user));
        history.push('/');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handleSubmit(e);
    }
  };

  return (
    <LoginLayout>
      <Grid container direction="column" spacing={2}>
        <Grid item xs className={classes.titleContainer}>
          <Typography className={classes.title} color="primary">
            {t('loginTitle')}
          </Typography>
          <Typography>
            {t('loginSubTitle')}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.container}>
        {useMediaQuery(theme.breakpoints.down('lg')) && (
        <div className={classes.logoContainer}>
          <svg height="64" width="240">
            <use xlinkHref={`${logoSvg}#img`} />
          </svg>
        </div>
        )}
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label={t('userEmail')}
            name="email"
            value={email}
            autoComplete="email"
            autoFocus={!email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleSpecialKey}
            helperText={failed && 'Invalid username or password'}
            variant="filled"
            className={classes.input}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label={t('userPassword')}
            name="password"
            value={password}
            type="password"
            autoComplete="current-password"
            autoFocus={!!email}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleSpecialKey}
            variant="filled"
            className={classes.input}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            onKeyUp={handleSpecialKey}
            variant="contained"
            color="secondary"
            disabled={!email || !password}
            fullWidth
            className={classes.submit}
          >
            {t('loginLogin')}
          </Button>
          <a href="#foo" aria-hidden className={classes.resetLink} onClick={() => history.push('/reset-password')}>
            {t('loginReset')}
          </a>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs className={classes.logoContainer}>
            <img src={fleemooIcon} alt="" width="125" height="30" />
            <FormControl variant="filled" fullWidth>
              <Select
                label={t('loginLanguage')}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className={classes.language}
              >
                {languageList.map((it) => (
                  <MenuItem key={it.code} value={it.code}>
                    {' '}
                    {it.name}
                    {' '}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
                )}
      />
    </LoginLayout>
  );
};

export default LoginPage;
