/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import Battery60Icon from '@material-ui/icons/Battery60';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import Battery20Icon from '@material-ui/icons/Battery20';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import ErrorIcon from '@material-ui/icons/Error';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  MenuItem,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ReactComponent as IgnitionIcon } from '../../public/images/icon/ignition.svg';

import { devicesActions } from '../store';
import EditCollectionView from '../settings/components/EditCollectionView';
import { useEffectAsync } from '../reactHelper';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      marginBottom: 0,
      borderRadius: '5px',
    },
    '& .MuiSvgIcon-root': {
      fill: '#0352DA',
    },
  },
  list: {
    maxHeight: '65vh',
    overflowY: 'scroll',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  icon: {
    width: '25px',
    height: '25px',
    marginRight: '15px',
    // filter: 'brightness(0) invert(1)',
  },
  iconBig: {
    width: '55px',
    height: '50px',
    marginRight: '15px',
  },
  listItem: {
    backgroundColor: 'white',
    boxShadow: '0 0 rgba(0,0,0,0)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  batteryText: {
    fontSize: '0.65rem',
    fontWeight: 'normal',
    lineHeight: '0.65rem',
  },
  green: {
    color: theme.palette.common.green,
  },
  red: {
    color: theme.palette.common.red,
  },
  gray: {
    color: theme.palette.common.gray,
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  indicators: {
    lineHeight: 1,
  },
  listText: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listText2: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  accordionSummary: {
    height: 45,
    // borderRadius:"50px",
  },
  accordionDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 20px 20px 20px',
  },
  accordionDetail: {
    background: '#E8F0FD',
    borderRadius: 5,
    fontSize: '12px',
    letterSpacing: '0.625px',
    padding: '0px 7px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceConnected: {
    display: 'flex',
    justifyContent: 'space-between',
    // paddingTop: 20,
    marginBottom: -5,
    marginTop: 15,
  },
  fancyScollbar: {
    height: '160px',
    overflow: 'scroll',
  },
  location: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    zIndex: 1,
    background: '#F2F7FF',
    boxShadow: '0 0 rgba(0,0,0,0)',
  },
  search: {
    borderRadius: '5px',
    backgroundColor: '#fff',
    border: 'none',
    outline: 'none',
    width: '100%',
    fontSize: '18px',
    letterSpacing: '0.75px',
    color: '#797979',
    padding: theme.spacing(1.6),
    // "& > *": {
    //   margin: theme.spacing(0, 1),
    // },
  },
  searchButton: {
    padding: theme.spacing(1.4),
    width: 50,
    borderRadius: 5,
    marginLeft: 10,
    background: '#0352DA',
    color: '#fff',
    '&:hover': {
      background: '#0352DA',
      opacity: 0.9,
    },
  },
  select: {
    fontSize: '12px',
    letterSpacing: '0.625px',
    color: '#A9B1C7',
    background: '#fff',
    minWidth: '150px',
    '& .MuiSvgIcon-root': {
      fill: '#0352DA',
    },
  },
  label: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.75px',
    color: '#2F3044',
  },
  selectContainer: {
    display: 'flex',
    gap: 10,
    paddingBottom: 12,
    paddingTop: 5,
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { items } = data;
  const item = items[index];
  const position = useSelector((state) => state.positions.items[item.id]);
  const showIgnition =
    position?.attributes.hasOwnProperty('ignition') &&
    position.attributes.ignition;

  return (
    <div style={style}>
      <div className={classes.root}>
        <Accordion
          className={classes.listItem}
          // onClick={() => dispatch(devicesActions.select(item))}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionSummary}
            onClick={() => setOpen(!open)}
          >
            {item.status === 'online' ? (
              <div
                style={{
                  height: '8px',
                  width: '10px',
                  borderRadius: '50%',
                  background: '#15E474',
                  marginTop: !open ? 12 : 20,
                  marginRight: 7,
                  marginLeft: -7,
                }}
              />
            ) : (
              <div
                style={{
                  height: '9px',
                  width: '10px',
                  borderRadius: '50%',
                  marginTop: 12,
                  marginRight: 7,
                  marginLeft: -7,
                }}
              />
            )}
            <img
              className={!open ? classes.icon : classes.iconBig}
              // src={item.src}
              src={`images/icon/${item.category || 'default'}.svg`}
              alt={item.name}
            />
            <div className={!open ? classes.listText : classes.listText2}>
              <span style={{ fontSize: '16px' }}>{item.name}</span>
              <span className={classes.location}>
                {position?.address != null && (
                  <LocationOnOutlinedIcon style={{ fill: '#000' }} />
                )}
                {position?.address != null &&
                  (!open
                    ? position?.address?.substr(0, 10)
                    : position?.address?.substr(0, 20))}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails key={item.id} className={classes.accordionDetails}>
            {position && (
              <>
                {showIgnition && (
                  <Grid item>
                    <SvgIcon component={IgnitionIcon} />
                  </Grid>
                )}
                <div className={classes.accordionDetail}>
                  {position.attributes.hasOwnProperty('alarm') && (
                  <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                    <IconButton size="small">
                      <ErrorIcon fontSize="small" className={classes.negative} />
                    </IconButton>
                  </Tooltip>
                  )}
                </div>
                {position.attributes.hasOwnProperty('batteryLevel') && (
                  <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                    <IconButton size="small">
                      {position.attributes.batteryLevel > 70 ? (
                        position.attributes.charge
                          ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                          : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                      ) : position.attributes.batteryLevel > 30 ? (
                        position.attributes.charge
                          ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                          : (<Battery60Icon fontSize="small" className={classes.medium} />)
                      ) : (
                        position.attributes.charge
                          ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                          : (<Battery20Icon fontSize="small" className={classes.negative} />)
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                <div>
                  {position.attributes.hasOwnProperty('ignition') && (
                  <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                    <IconButton size="small">
                      {position.attributes.ignition ? (
                        <FlashOnIcon fontSize="small" className={classes.positive} />
                      ) : (
                        <FlashOffIcon fontSize="small" className={classes.neutral} />
                      )}
                    </IconButton>
                  </Tooltip>
                  )}
                </div>
                {/* <div className={classes.accordionDetail}>
                  <span style={{ color: '#15E474' }}>
                    {position?.attributes?.distance}
                    {' '}
                    kms
                  </span>
                </div> */}
                <div className={classes.accordionDetail}>
                  <span>
                    {position?.attributes?.totalDistance}
                    {' '}
                    km
                  </span>
                </div>
                <div className={classes.accordionDetail}>
                  <span
                    style={{ color: '#0352DA', cursor: 'pointer' }}
                    onClick={() => history.push(`/settings/devices/${item.id}`)}
                  >
                    <CreateIcon />
                  </span>
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <div style={{ width: '100%', height: '7px' }} />
      </div>
    </div>
  );
};

const DeviceView = ({ updateTimestamp, onMenuClick, filter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);
  const t = useTranslation();

  const items = useSelector((state) => state.devices.items);
  const [filteredItems, setFilteredItems] = useState(null);

  useEffect(() => {
    const array = Object.values(items);
    setFilteredItems(
      filter.trim().length > 0
        ? array.filter((item) => `${item.name} ${item.uniqueId}`.toLowerCase().includes(filter?.toLowerCase()))
        : array,
    );
  }, [filter, items]);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [updateTimestamp]);

  return (
    <>
      <div className={classes.deviceConnected}>
        <p className="card-big-text" style={{ fontWeight: 500 }}>
          {Object.values(items).length}
          {' '}
          {t('deviceTitle')}
        </p>
        <p
          className="card-big-text"
          style={{ color: '#0352DA', fontWeight: 500 }}
        >
          {Object.values(items).filter((item) => item.status === 'online').length}
          {' '}
          {t('deviceStatusOnline')}
        </p>
      </div>
      <br />
      <AutoSizer className={classes.list}>
        {({ height, width }) => (
          <List disablePadding>
            <FixedSizeList
              width={width}
              height={height}
              itemCount={filteredItems.length}
              itemData={{ items: filteredItems, onMenuClick }}
              itemSize={72}
              overscanCount={10}
              innerRef={listInnerEl}
            >
              {DeviceRow}
            </FixedSizeList>
          </List>
        )}
      </AutoSizer>
    </>
  );
};

const DevicesList = ({ filter }) => (
  <EditCollectionView content={DeviceView} editPath="/settings/device" endpoint="devices" disableAdd filter={filter} />
);

export default DevicesList;
